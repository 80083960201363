import React from 'react'
import clapperboard from '../images/clapperboard.png'
import logo from '../images/logo.png'
import ServiceItem from '../components/ServiceItem'
import Servicesbottom from '../components/Servicesbottom'
import ServiceImages from '../components/ServiceImages'
const Services = () => {
    return (
        <>
            <div className="service w-full h-fit pt-[120px]">
                <div className='bg-image w-full h-[400px] md:flex sm:items-center sm:justify-start md:-space-x-30'>
                    <img src={clapperboard} alt="shivanifilms" className='sm:w-[900px] md:w-[800px]' />
                    <p className='text-[#C3292B] text-justify w-[80%] md:text-xl md:w-[60%] md:tracking-tight sm:text-base sm:leading-6 mx-auto leading-4 p-4 md:mr-16 '>Welcome to Zerodha Films Production, a prominent Media Agency known for its outstanding reputation in the industry. We specialize in delivering exceptional Audio-Visual Production services, catering to a diverse range of media platforms. As a forward-thinking E-Learning Company, our commitment lies in providing high-quality educational content that captivates and enlightens learners. With a well-established presence in the realms of Public Relations and Advertising, we take pride in crafting compelling campaigns that effectively communicate our clients' messages to the masses. Whether it's promoting products, services, or causes, we excel in creating engaging content that makes a lasting impact.</p>
                </div>
             
                <ServiceItem />
                <ServiceImages/>
                <Servicesbottom />
            </div>
        </>
    )
}

export default Services